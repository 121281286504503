import {
  DailyAudio,
  DailyProvider,
  DailyVideo,
  useParticipantIds
} from '@daily-co/daily-react';
import { conversationStore } from 'entities/tavus/conversation';
import { DAILY_CALL_STATES, events } from 'features/daily-call/model';
import { dailyCallStore } from 'features/daily-call/model/dailyCallStore';
import { useEffect, Fragment, useRef, useState } from 'react';
import { Loader, ToolTip } from 'shared/ui';
import {
  hotkeyDescriptions,
  TavusEmbedControlBar,
  useKeyboardFullScreenAndCaptionsBinding
} from 'widgets/call-controls';
import { tavusPersonaStore } from 'entities/tavus/persona';
import { GreenScreenVideo } from 'features/green-screen-video';
import { Captions } from 'widgets/captions';
import { useTimerConversation } from 'entities/tavus/conversation/lib';
import { RiFullscreenExitLine, RiFullscreenFill } from 'react-icons/ri';
import { cn, useFullScreen, useMouseVisibility } from 'shared/lib';
import { ToggleCaptions } from 'features/toggle-captions';

export const TavusEmbedConference = () => {
  const { currentConversation, endConversation } = conversationStore();

  const { toggleFullScreen, isFullScreen, videoContainer } = useFullScreen();

  const {
    startHairCheck,
    callObject,
    handleNewMeetingState,
    currentState,
    startLeavingCall,
    joinCall,
    toggleCaptions,
    withCaptions,
    messages,
    addMessage
  } = dailyCallStore();

  useEffect(() => {
    if (currentConversation) {
      startHairCheck(currentConversation.conversation_url);
    }
  }, [currentConversation]);

  useEffect(() => {
    if (!callObject) return;

    handleNewMeetingState();
    events.forEach((event) => callObject.on(event, handleNewMeetingState));

    callObject.on('transcription-message', (message) => addMessage(message));

    return () => {
      events.forEach((event) => callObject.off(event, handleNewMeetingState));
      callObject.off('transcription-message', () => {});
    };
  }, [callObject]);

  const handleEndCall = () => {
    if (currentConversation?.conversation_id) {
      endConversation(messages);
      startLeavingCall();
    }
  };

  useEffect(() => {
    if (currentState === DAILY_CALL_STATES.STATE_HAIRCHECK) {
      if (currentConversation) {
        joinCall(currentConversation?.conversation_url);
      }
    }
  }, [currentState]);

  useTimerConversation(currentConversation, callObject);
  useKeyboardFullScreenAndCaptionsBinding({ toggleFullScreen, toggleCaptions });
  const isVisible = useMouseVisibility();
  if (!callObject) return null;

  return (
    <div
      ref={videoContainer}
      className={`ag-flex ag-items-center ag-justify-center ag-relative ag-h-full`}
    >
      <DailyProvider callObject={callObject}>
        {[
          DAILY_CALL_STATES.STATE_JOINING,
          DAILY_CALL_STATES.STATE_JOINED,
          DAILY_CALL_STATES.STATE_ERROR
        ].includes(currentState) ? (
          <div className="ag-w-full ag-h-full ag-flex ag-flex-col ag-items-center ag-justify-between">
            <Video />
            <div
              className={cn(
                'ag-flex ag-items-center ag-gap-6 ag-absolute ag-top-3.5 ag-right-3.5',
                isVisible ? 'ag-block' : 'ag-hidden'
              )}
            >
              <div className="ag-relative ag-text-black group">
                <ToggleCaptions
                  toggleCaptions={toggleCaptions}
                  withCaptions={withCaptions}
                />
                <ToolTip
                  className="ag-right-5 ag-top-5 ag-z-10 ag-text-white"
                  text={hotkeyDescriptions.toggleCaptions}
                />
              </div>
              <button className="ag-relative group" onClick={toggleFullScreen}>
                {isFullScreen ? (
                  <RiFullscreenExitLine color="black" size={20} />
                ) : (
                  <RiFullscreenFill color="black" size={20} />
                )}
                <ToolTip
                  className="ag-right-5 ag-top-5 ag-z-10 ag-text-white"
                  text={hotkeyDescriptions.toggleFullScreen}
                />
              </button>
            </div>
            <div className="ag-absolute ag-bottom-24 ag-left-0 ag-right-0 ag-z-[1000]">
              <Captions withCaptions={withCaptions} messages={messages} />
            </div>

            <TavusEmbedControlBar onCallEnd={handleEndCall} />
          </div>
        ) : (
          <div className="ag-w-full ag-h-screen ag-top-0 ag-left-0 ag-right-0 ag-bottom-0 ag-object-cover ag-z-20 ag-flex ag-items-center ag-justify-center">
            <Loader size={96} />
          </div>
        )}

        <DailyAudio />
      </DailyProvider>
    </div>
  );
};

const Video = () => {
  const remoteParticipantIds = useParticipantIds({ filter: 'remote' });
  const ref = useRef<HTMLVideoElement>(null);
  const currentPersona = tavusPersonaStore((state) => state.currentPersona);

  return (
    <div className="ag-relative ag-w-full ag-h-full">
      {remoteParticipantIds.length > 0 ? (
        remoteParticipantIds.map((id) => (
          <Fragment key={id}>
            <DailyVideo
              className={'ag-absolute -ag-z-10 ag-opacity-0'}
              automirror
              ref={ref}
              key={id}
              sessionId={id}
              type={'video'}
              playableStyle={{ width: '100%', height: '100%' }}
              fit="cover"
            />
            {currentPersona?.custom_background?.includes('video;') && (
              <video
                muted
                loop
                autoPlay
                className={
                  'ag-absolute ag-object-cover ag-h-[calc(100%-4.5rem)] ag-w-full'
                }
                src={currentPersona?.custom_background?.replace('video;', '')}
              />
            )}
            <GreenScreenVideo
              background={currentPersona?.custom_background}
              videoRef={ref}
              classNames={'ag-absolute ag-w-full ag-h-full ag-inset-0'}
            />
          </Fragment>
        ))
      ) : (
        <div
          className={
            'ag-w-full ag-h-screen ag-flex ag-justify-center ag-items-center ag-pb-5 ag-flex-col'
          }
        >
          <Loader size={96} />
        </div>
      )}
    </div>
  );
};
