import { FC, useEffect } from 'react';
import { FormikErrors } from 'formik';
import { ICreatePersonaReq } from 'entities/persona';
import { replicaStore } from 'entities/tavus/replica/model/replicaStore';
import { TavusReplicaItem } from './TavusReplicaItem';
import { useInView } from 'react-intersection-observer';
import { Loader } from 'shared/ui';

type Props = {
  defaultReplicaId: string;
  onChange: (
    value: string
  ) => Promise<void> | Promise<FormikErrors<ICreatePersonaReq>>;
};

export const TavusChooseReplica: FC<Props> = ({
  defaultReplicaId,
  onChange
}) => {
  const { replicas, changePage, replicasLoading } = replicaStore((state) => ({
    replicas: state.replicas,
    changePage: state.changePage,
    replicasLoading: state.replicasLoading
  }));

  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) changePage();
  }, [inView]);

  return (
    <div className="ag-w-full ag-mb-14 ag-min-h-96 ag-relative">
      <div className="ag-sticky ag-top-14 ag-bg-neutral-900 ag-z-[49] ag-pt-4 ag-h-[120px]">
        <div className="ag-w-full  ag-flex ag-items-center ag-justify-between ag-mb-2 sm:ag-mb-6 ag-gap-4">
          <div>Choose avatar</div>
        </div>
      </div>
      <div className="ag-h-full ag-overflow-auto ag-scrollbar-none ag-pt-2">
        <div className="ag-grid ag-grid-cols-3 md:ag-grid-cols-5 ag-gap-2 md:ag-gap-4 ag-mb-4">
          {replicas.map((replica, index) => (
            <div
              ref={replicas.length - 1 === index ? ref : null}
              key={replica.replica_id}
            >
              <TavusReplicaItem
                onChooseReplica={() => onChange(replica.replica_id)}
                isActive={replica.replica_id === defaultReplicaId}
                replica={replica}
              />
            </div>
          ))}
        </div>
        {replicasLoading && (
          <div className="ag-flex ag-justify-center">
            <Loader size={100} />
          </div>
        )}
      </div>
    </div>
  );
};
