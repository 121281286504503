import { AxiosResponse } from 'axios';
import { Conversation, IVideoResponse } from '../model';
import { tavusHttpService } from 'shared/api';
import { userStore } from 'entities/user';
import { ITMessageDaily } from 'shared/model';

const tavusConversationsEndpoint = '/conversation/';

export const conversationApi = {
  createConversation: async (
    personaId: string,
    key: string | undefined,
    language?: string,
    preconversationId?: string | null
  ) => {
    const { data }: AxiosResponse<Conversation> = await tavusHttpService.post(
      tavusConversationsEndpoint + `start/${personaId}`,
      {},
      {
        headers: {
          Authorization: userStore.getState().user?.internalKey || key
        },
        params: {
          language,
          preconversation_id: preconversationId
        }
      }
    );

    return data;
  },
  downloadVideo: async (conversationId: string) => {
    const { data } = await tavusHttpService.get<IVideoResponse>(
      tavusConversationsEndpoint + `video/${conversationId}`
    );
    return data;
  },
  endConversation: async (
    conversationId: string,
    messages: ITMessageDaily[],
    emotionalTimeline: string
  ) => {
    messages = messages.map((message) => ({
      ...message,
      user_name: message.userName
    }));

    const data: AxiosResponse<any> = await tavusHttpService.post(
      `${tavusConversationsEndpoint}${conversationId}/end`,
      {
        messages,
        emotional_timeline: emotionalTimeline
      }
    );

    return data;
  }
};
