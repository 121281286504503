import {
  FEATURE_FLAG_EMBED,
  FEATURE_FLAG_SHARE,
  FEATURE_FLAG_STOP_TALK,
  FEATURE_FLAG_T_AVATARS,
  REACT_APP_FEATURE_FLAG_CONVERSATION_CONFIG,
  REACT_APP_FEATURE_FLAT_CONVERSATION_TIMER
} from './config';

export const featureFlags = {
  stopTalking: FEATURE_FLAG_STOP_TALK === 'true',
  embed: FEATURE_FLAG_EMBED === 'true',
  share: FEATURE_FLAG_SHARE === 'true',
  tAvatars: FEATURE_FLAG_T_AVATARS === 'true',
  conversationTimer: REACT_APP_FEATURE_FLAT_CONVERSATION_TIMER === 'true',
  conversationConfig: REACT_APP_FEATURE_FLAG_CONVERSATION_CONFIG === 'true'
};
