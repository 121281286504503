export enum EPreconversationType {
  EMAIL = 'email',
  TEXT = 'text'
}
export interface IPerconversationField {
  label: string;
  name: string;
  type: EPreconversationType;
}
export interface IPreconversationConfig {
  context: string;
  fields: IPerconversationField[];
}
export interface ITavusPersonaFormik {
  name: string;
  model: string;
  defaultReplicaId: string;
  context: string;
  systemPrompt: string;
  customBackground: string;
  customGreeting: string;
  timerConfig: string;
  preconversationConfig: IPreconversationConfig;
  voiceId: string;
}

export interface ICreateTavusPersonaReq {
  persona_name: string;
  context: string;
  system_prompt: string;
  default_replica_id: string;
  custom_background?: string;
  custom_greeting?: string;
  model: string;
  timer_config: string;
  preconversation_config?: IPreconversationConfig;
  voice_id: string;
}

export interface ICreateTavusPersonaRes {
  persona_id: string;
  persona_name: string;
  created_at: string;
}

interface FunctionParameters {
  type: string;
  properties: {
    location: {
      type: string;
      description: string;
    };
    unit: {
      type: string;
      enum: string[];
    };
  };
  required: string[];
}

interface ToolFunction {
  name: string;
  description: string;
  parameters: FunctionParameters;
}

interface Tool {
  type: string;
  function: ToolFunction;
}

interface LLM {
  model: string;
  base_url: string;
  api_key: string;
  tools: Tool[];
}

interface VoiceSettings {
  speed: string;
  emotion: string[];
}

interface TTS {
  api_key: string;
  tts_engine: string;
  external_voice_id: string;
  voice_settings: VoiceSettings;
  playht_user_id: string;
}

interface VQA {
  enable_vision: string;
}

interface Layers {
  llm: LLM;
  tts: TTS;
  vqa: VQA;
}

export interface ITavusPersona {
  id: string;
  persona_name: string;
  system_prompt: string;
  default_replica_id: string;
  custom_background: string;
  context: string;
  layers: Layers;
  updated_at: string;
  user_id: string;
  custom_greeting?: string;
  model: string;
  timer_config: string;
  preconversation_config?: IPreconversationConfig;
  voice_id?: string;
}

export interface ListPersonas {
  items: ITavusPersona[];
  total: number;
  page: number;
  size: number;
  pages: number;
}
