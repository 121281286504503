import { FormikProps } from 'formik';
import { FC, useState } from 'react';
import { clsx } from 'clsx';
import FormError from 'components/Forms/FormError/FormError';
import Divider from 'components/Divider';
import { OutlinedButton } from 'shared/ui';
import { SlRefresh } from 'react-icons/sl';
import { ITavusPersonaFormik } from 'entities/tavus/persona';
import { replicaStore } from 'entities/tavus/replica/model/replicaStore';
import { AvatarListModal } from 'features/avatar-list-modal';
import Dropdown from 'components/shared/Dropdown';
import { VscChevronDown } from 'react-icons/vsc';
import { voiceStore } from 'entities/tavus/voice';
import { VoiceSelectButton } from './VoiceSelectButton';
import { VoiceModal } from 'features/voice-modal';

interface IProps {
  formik: FormikProps<ITavusPersonaFormik>;
}

export const AvatarIdentityForm: FC<IProps> = ({ formik }) => {
  const [openAvatarListModal, setOpenAvatarListModal] = useState(false);
  const currentReplica = replicaStore((state) => state.currentReplica);
  const [openVoiceModal, setOpenVoiceModal] = useState(false);
  const { voices } = voiceStore();
  const currentVoice = voices?.find(
    (item) => item.external_id === formik.values.voiceId
  );
  return (
    <div>
      <div className="ag-flex ag-flex-col ag-relative ag-mb-6">
        <label
          className="ag-mb-1.5 ag-text-neutral-900 ag-text-sm ag-font-semibold"
          htmlFor="name"
        >
          <div>Name</div>
        </label>

        <input
          className={clsx(
            'ag-border ag-text-neutral-900 ag-border-neutral-300 ag-py-2 ag-px-3 ag-rounded-md ag-text-sm ag-outline-none ag-ring-0 focus:ag-outline-none focus:ag-ring-0',
            formik.errors.name && formik.touched.name
              ? 'ag-text-red-900  focus:ag-border-red-500'
              : 'placeholder:ag-text-neutral-500 focus:ag-border-primary-800'
          )}
          placeholder="My Video Agent"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.name}
          name="name"
          id="name"
        />
        {formik.errors.name && formik.touched.name && (
          <FormError>{formik.errors.name}</FormError>
        )}
      </div>
      <div className="ag-flex ag-flex-col ag-mb-6">
        <label
          className="ag-mb-1.5 ag-text-neutral-900 ag-text-sm ag-font-semibold"
          htmlFor="description"
        >
          <div>Description</div>
        </label>
        <textarea
          className={clsx(
            'ag-border ag-text-neutral-900 ag-border-neutral-300 ag-py-2 ag-px-3 ag-rounded-md ag-text-sm ag-outline-none ag-ring-0 focus:ag-outline-none focus:ag-ring-0',
            formik.errors.systemPrompt && formik.touched.systemPrompt
              ? 'ag-text-red-900  focus:ag-border-red-500'
              : 'placeholder:ag-text-neutral-500 focus:ag-border-primary-800'
          )}
          placeholder="For example, “An expert in digital marketing strategies.”"
          name="systemPrompt"
          id="systemPrompt"
          rows={3}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.systemPrompt}
        />
        <div className="ag-flex ag-items-center ag-justify-between">
          <div>
            {formik.errors.systemPrompt && formik.touched.systemPrompt && (
              <FormError>{formik.errors.systemPrompt}</FormError>
            )}
          </div>
        </div>
      </div>
      <Divider />
      <div className="ag-mb-1.5 ag-text-neutral-900 ag-text-sm ag-font-semibold">
        <div className={'ag-mb-2 ag-mt-6'}>Avatar</div>
        <div
          className={
            'ag-rounded-lg ag-bg-neutral-50 ag-border ag-border-neutral-200 ag-flex ag-items-center ag-justify-between ag-p-2 ag-mb-6'
          }
        >
          <div className={'ag-flex ag-items-center'}>
            {currentReplica &&
              (currentReplica.thumbnail_url ? (
                <img
                  className={
                    'ag-size-[4.25rem] ag-rounded-lg ag-mr-2 ag-object-cover'
                  }
                  src={currentReplica.thumbnail_url}
                />
              ) : (
                <video
                  className={
                    'ag-size-[4.25rem] ag-rounded-lg ag-mr-2 ag-object-cover'
                  }
                  src={currentReplica.thumbnail_video_url}
                />
              ))}
            <div>
              <div className={'ag-text-md'}>{currentReplica?.replica_name}</div>
              <div className={'ag-tex-sm ag-text-neutral-600'}>
                Interactive Avatar
              </div>
            </div>
          </div>
          <OutlinedButton
            onClick={() => setOpenAvatarListModal(true)}
            className={'ag-flex ag-gap-2 ag-px-3 ag-py-2'}
          >
            <SlRefresh size={20} />
            <div className={'ag-text-sm'}>Change</div>
          </OutlinedButton>
        </div>
        <Divider />
      </div>
      <AvatarListModal
        setOpen={setOpenAvatarListModal}
        open={openAvatarListModal}
      />
      <div className={'ag-mb-2 ag-mt-6 ag-font-semibold ag-text-sm'}>Voice</div>
      <VoiceSelectButton
        currentVoice={currentVoice || null}
        onClick={() => setOpenVoiceModal(true)}
      />
      <VoiceModal
        open={openVoiceModal}
        setOpen={setOpenVoiceModal}
        currentVoice={currentVoice}
        chooseVoice={(voice) =>
          formik.setFieldValue('voiceId', voice?.external_id || '')
        }
      />
    </div>
  );
};
