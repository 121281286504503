import { create } from 'zustand';
import { replicaApi } from '../api';
import { toast } from 'react-toastify';
import { Replica } from './replicaTypes';
import { userStore } from 'entities/user';

type State = {
  replicas: Replica[];
  replicasLoading: boolean;
  currentReplica: Replica | null;
  replicaNameFilter: string;
  total: number;
  page: number;
  size: number;
  pages: number;
};
type Actions = {
  fetchReplicas: () => Promise<void>;
  fetchOne: (id: string) => Promise<Replica | undefined>;
  getReplicaById: (id: string) => Replica | undefined;
  setCurrentReplica: (replica: Replica | null) => void;
  changePage: () => void;
  setReplicaNameFilter: (name: string) => void;
};

type Store = State & Actions;

export const replicaStore = create<Store>((set, get) => ({
  replicas: [],
  replicasLoading: false,
  currentReplica: null,
  replicaNameFilter: '',
  total: 0,
  page: 1,
  size: 12,
  pages: 1,
  setReplicaNameFilter: (name) => {
    set({
      replicas: [],
      total: 0,
      page: 1,
      size: 100,
      pages: 1,
      replicaNameFilter: name
    });
    get().fetchReplicas();
  },
  changePage: () => {
    set((state) => ({
      page: state.page + 1
    }));
    if (get().total !== get().replicas.length) {
      get().fetchReplicas();
    }
  },
  setCurrentReplica: (replica) => {
    set({ currentReplica: replica });
  },
  fetchReplicas: async () => {
    set({ replicasLoading: true });
    try {
      const replicaNameFilter = get().replicaNameFilter;
      const currentPage = get().page;
      const { items, total, page, size, pages } = await replicaApi.fetchAll(
        currentPage,
        replicaNameFilter
      );
      const newReplicas =
        currentPage !== page ? [...get().replicas, ...items] : items;
      set({
        replicas: newReplicas,
        total,
        page,
        size,
        pages
      });
    } catch (error) {
      toast.error(
        'There was a problem when we tried to get replicas list. Please try again later.'
      );
    } finally {
      set({ replicasLoading: false });
    }
  },
  fetchOne: async (id: string) => {
    set({ replicasLoading: true });
    try {
      return await replicaApi.fetch(id);
    } catch (error) {
      toast.error(
        'There was a problem when we tried to get replica details. Please try again later.'
      );
    } finally {
      set({ replicasLoading: false });
    }
  },
  getReplicaById: (id) => {
    const allReplicas = get().replicas;

    return allReplicas.find((a) => a.replica_id === id);
  }
}));
