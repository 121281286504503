import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState
} from 'react';
import Modal from 'components/shared/Modal';
import Divider from 'components/Divider';
import { IoSearchOutline } from 'react-icons/io5';
import { useDebounce } from 'shared/lib';
import { FilledButton, Loader, OutlinedButton } from 'shared/ui';
import { replicaStore } from 'entities/tavus/replica/model/replicaStore';
import { AvatarListItem } from './AvatarListItem';
import { Replica } from 'entities/tavus/replica';
import { useInView } from 'react-intersection-observer';

interface IProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
  open: boolean;
}

export const AvatarListModal: FC<IProps> = ({ setOpen, open }) => {
  const [nameInputValue, setNameInputValue] = useState('');
  const {
    currentReplica,
    replicas,
    setCurrentReplica,
    changePage,
    replicasLoading,
    setReplicaNameFilter
  } = replicaStore((state) => ({
    replicas: state.replicas,
    currentReplica: state.currentReplica,
    setCurrentReplica: state.setCurrentReplica,
    changePage: state.changePage,
    replicasLoading: state.replicasLoading,
    setReplicaNameFilter: state.setReplicaNameFilter
  }));

  const changeFilterNameValue = useDebounce(setReplicaNameFilter, 500);

  const [selectedReplica, setSelectedReplica] = useState<Replica | null>(
    currentReplica
  );

  useEffect(() => {
    if (!open) {
      setSelectedReplica(null);
    } else {
      setSelectedReplica(currentReplica);
    }
  }, [open]);

  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) changePage();
  }, [inView]);

  return (
    <Modal
      className={
        'ag-max-w-[40.5rem] ag-max-h-[calc(100vh-5rem)] ag-overflow-y-auto'
      }
      noPadding
      setOpen={(value) => {
        setOpen(value);
      }}
      open={open}
    >
      <div>
        <h2 className={'ag-p-6 ag-font-semibold ag-text-lg'}>Avatars</h2>
        <Divider />
      </div>
      <div>
        <div className="ag-p-4 z-20 ag-flex">
          <div className={'ag-relative'}>
            <IoSearchOutline
              size={20}
              className={
                'ag-absolute ag-text-neutral-500 ag-left-3 ag-top-1/2 ag-transform -ag-translate-y-1/2'
              }
            />
            <input
              className="ag-rounded-md ag-py-2 ag-pl-10 ag-pr-3 ag-border-neutral-300 ag-max-w-80 ag-h-10 ag-outline-none ag-ring-0 focus:ag-ring-0 focus:ag-outline-none focus:ag-border-primary-800 ag-mr-2"
              type="text"
              value={nameInputValue}
              onChange={(e) => {
                setNameInputValue(e.target.value);
                changeFilterNameValue(e.target.value);
              }}
              placeholder={'Avatar name'}
            />
          </div>
        </div>
        <div className="ag-px-4 ag-max-h-[calc(60vh-5rem)] ag-overflow-y-auto ag-scrollbar-none">
          <>
            <div>
              <h3 className="ag-text-sm ag-font-semibold ag-mb-2">
                Stock Avatars
              </h3>
              <div className="ag-flex ag-gap-2 ag-flex-wrap">
                {replicas.map((replica, inx) => (
                  <div
                    ref={replicas.length - 1 === inx ? ref : null}
                    key={replica.replica_id}
                  >
                    <AvatarListItem
                      replica={replica}
                      onChooseReplica={(replica) => setSelectedReplica(replica)}
                      isActive={
                        selectedReplica?.replica_id === replica.replica_id
                      }
                    />
                  </div>
                ))}
              </div>
              {replicasLoading && (
                <div className="ag-flex ag-justify-center">
                  <Loader size={36} />
                </div>
              )}
            </div>
          </>
        </div>
      </div>
      <Divider />
      <div className={'ag-p-6 ag-flex ag-justify-end ag-gap-2'}>
        <OutlinedButton
          onClick={() => {
            setOpen(false);
          }}
          className={'ag-py-2.5 ag-px-[18px]'}
        >
          Cancel
        </OutlinedButton>
        <FilledButton
          onClick={() => {
            if (selectedReplica) {
              setCurrentReplica(selectedReplica);
            }
            setOpen(false);
          }}
          className={'ag-py-2.5 ag-px-[18px]'}
        >
          Apply
        </FilledButton>
      </div>
    </Modal>
  );
};
