import { create } from 'zustand';
import { Conversation } from './conversationTypes';
import { toast } from 'react-toastify';
import { conversationApi } from '../api';
import { ITavusPersona } from 'entities/tavus/persona';
import { downloadFile, getErrorMessageV2, logger } from 'shared/lib';
import { ITMessageDaily } from 'shared/model';
import { useMorph } from 'features/recognize-emotions';

type State = {
  currentConversation: Conversation | null;
  downloadVideoLoading: boolean;
  conversationLoading: boolean;
  callIsFinishing: boolean;
};

type Action = {
  startConversation: (
    currentPersona: ITavusPersona | null,
    key: string | undefined,
    language?: string,
    preconversationId?: string | null
  ) => Promise<void>;
  endConversation: (messages: ITMessageDaily[]) => Promise<void>;
  downloadVideo: (conversationId: string) => Promise<void>;
  getStreamLinkVideo: (
    conversationId: string
  ) => Promise<string | undefined | null>;
};

type Store = State & Action;

export const conversationStore = create<Store>((set, get) => ({
  currentConversation: null,
  conversationLoading: false,
  downloadVideoLoading: false,
  startConversation: async (
    currentPersona,
    key,
    language,
    preconversationId
  ) => {
    if (!currentPersona) {
      toast.error('Please select a persona to start the conversation.');
      throw new Error('No persona selected.');
    }
    set({ conversationLoading: true });
    try {
      const conversation = await conversationApi.createConversation(
        currentPersona.id!,
        key,
        language,
        preconversationId
      );

      set({ currentConversation: conversation });
    } catch (error) {
      // const message = getErrorMessageV2(
      //   error,
      //   'Could not start the conversation.',
      //   true
      // );
      // toast.error(message);
      toast.error("Couldn't start the conversation. Please try again later.");
      throw error;
    } finally {
      set({ conversationLoading: false });
    }
  },
  getStreamLinkVideo: async (conversationId: string) => {
    try {
      const data = await conversationApi.downloadVideo(conversationId);
      return data?.stream_url;
    } catch (error) {
      toast.error(getErrorMessageV2(error, 'Please try again later.'));
    } finally {
      set({ downloadVideoLoading: false });
    }
  },
  downloadVideo: async (conversationId) => {
    set({ downloadVideoLoading: true });
    try {
      const data = await conversationApi.downloadVideo(conversationId);
      if (data.download_url) {
        downloadFile(data.download_url);
      }
    } catch (error) {
      toast.error(getErrorMessageV2(error, 'Please try again later.'));
    } finally {
      set({ downloadVideoLoading: false });
    }
  },
  callIsFinishing: false,
  endConversation: async (messages) => {
    if (get().callIsFinishing) {
      logger.error('Last end conversation request is not finished yet.');
      return;

      // TODO: Get rid of second request (in return of TavusConvference)
    }
    const timeline = [...useMorph.getState().timeline];
    const emotionalTimeline = timeline.length ? timeline.join('\n') : '';

    useMorph.getState().clearTimeline();
    logger.debug('timeline', timeline);
    set({ callIsFinishing: true });
    const conversationId = get().currentConversation?.conversation_id;

    if (!conversationId) {
      toast.error('No conversation to end.');
      return;
    }

    try {
      await conversationApi.endConversation(
        conversationId,
        messages,
        emotionalTimeline
      );
      logger.debug('call end request');
      set({ currentConversation: null });
    } catch (error) {
      toast.error("Couldn't end the conversation. Please try again later.");
    } finally {
      set({ callIsFinishing: false });
    }
  }
}));
