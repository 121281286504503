import React, { FC, useState } from 'react';
import { FormikProps } from 'formik';
import clsx from 'clsx';
import FormError from 'components/Forms/FormError/FormError';
import { ITavusPersonaFormik } from 'entities/tavus/persona';
import { aiModels } from 'entities/tavus/ai-model';
import { ChooseModelAiModal } from 'features/choose-model-ai';
import { VscChevronDown } from 'react-icons/vsc';
import { featureFlags } from 'shared/model';

type Props = {
  formik: FormikProps<ITavusPersonaFormik>;
  classNameBg?: string;
};

export const TavusPersonaDataForm: FC<Props> = ({
  formik,
  classNameBg = 'ag-bg-neutral-800'
}) => {
  const [openChoseAiModelModal, setOpenChoseAiModelModal] = useState(false);
  return (
    <div className="ag-space-y-10">
      <div className="ag-flex ag-flex-col ag-relative">
        <label className="ag-mb-4 ag-text-white" htmlFor="name">
          <div>Name</div>
          <div className="ag-text-sm ag-text-neutral-400">
            What’s the name of your Video Agent?
          </div>
        </label>

        <input
          className={clsx(
            classNameBg,
            'ag-border ag-border-neutral-600 ag-py-2 ag-px-3 ag-rounded-md ag-text-sm ag-outline-none ag-ring-0 focus:ag-outline-none focus:ag-ring-0',
            formik.errors.name && formik.touched.name
              ? 'ag-text-red-900  focus:ag-border-red-500'
              : 'ag-text-white placeholder:ag-text-gray-400 focus:ag-border-primary-800'
          )}
          placeholder="My Video Agent"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.name}
          name="name"
          id="name"
        />
        {formik.errors.name && formik.touched.name && (
          <FormError>{formik.errors.name}</FormError>
        )}
      </div>

      <div className="ag-flex ag-flex-col">
        <label className="ag-mb-4 ag-text-white" htmlFor="systemPrompt">
          <div>Description</div>
          <div className="ag-text-sm ag-text-neutral-400">
            In a few words, describe your Video Agent.
          </div>
        </label>
        <textarea
          className={clsx(
            classNameBg,
            'ag-border ag-border-neutral-600 ag-py-2 ag-px-3 ag-rounded-md ag-text-sm ag-outline-none ag-ring-0 focus:ag-outline-none focus:ag-ring-0',
            formik.errors.systemPrompt && formik.touched.systemPrompt
              ? 'ag-text-red-900  focus:ag-border-red-500'
              : 'ag-text-white placeholder:ag-text-gray-400 focus:ag-border-primary-800'
          )}
          placeholder="For example, “As a Life Coach, you are a dedicated professional who specializes in...”"
          name="systemPrompt"
          id="systemPrompt"
          rows={3}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.systemPrompt}
        />
        <div className="ag-flex ag-items-center ag-justify-between">
          <div>
            {formik.errors.systemPrompt && formik.touched.systemPrompt && (
              <FormError>{formik.errors.systemPrompt}</FormError>
            )}
          </div>
          <div className="ag-text-xs ag-text-neutral-400 ag-text-right ag-mt-1">
            {formik.values.systemPrompt.length}/80 characters
          </div>
        </div>
      </div>

      <div className="ag-flex ag-flex-col ag-relative">
        <label className="ag-mb-4 ag-text-white" htmlFor="name">
          <div>Model</div>
          <div className="ag-text-sm ag-text-neutral-400">
            Select the AI model that powers your agent&apos;s responses and
            interactions
          </div>
        </label>
        <div className="ag-relative">
          <input
            className={clsx(
              classNameBg,
              'ag-border ag-w-full ag-border-neutral-600 ag-py-2 ag-px-3 ag-rounded-md ag-text-sm ag-outline-none ag-ring-0 focus:ag-outline-none focus:ag-ring-0',
              formik.errors.name && formik.touched.name
                ? 'ag-text-red-900  focus:ag-border-red-500'
                : 'ag-text-white placeholder:ag-text-gray-400 focus:ag-border-primary-800'
            )}
            placeholder="My Video Agent"
            onClick={() => setOpenChoseAiModelModal(true)}
            value={
              aiModels.find((model) => model.value === formik.values.model)
                ?.name
            }
            name="model"
            id="name"
            readOnly
          />
          <VscChevronDown
            className={`ag-right-2 ag-transform ag-top-1/2 -ag-translate-y-1/2 ag-absolute ${
              openChoseAiModelModal && 'ag-rotate-180'
            } `}
          />
        </div>

        {formik.errors.name && formik.touched.name && (
          <FormError>{formik.errors.name}</FormError>
        )}
      </div>

      <div className="ag-flex ag-flex-col">
        <label className="ag-mb-4 ag-text-white" htmlFor="greeting">
          <div>Greeting(Optional)</div>
          <div className="ag-text-sm ag-text-neutral-400">
            This is the first thing they’ll say when starting a new
            conversation.
          </div>
        </label>
        <textarea
          className={clsx(
            classNameBg,
            'ag-border ag-border-neutral-600 ag-py-2 ag-px-3 ag-rounded-md ag-text-sm ag-outline-none ag-ring-0 focus:ag-outline-none focus:ag-ring-0',
            formik.errors.customGreeting && formik.touched.customGreeting
              ? 'ag-text-red-900  focus:ag-border-red-500'
              : 'ag-text-white placeholder:ag-text-gray-400 focus:border-primary-800'
          )}
          placeholder={`For example, “Hello! I'm your personal assistant. How can I assist you today?”`}
          name="customGreeting"
          id="greeting"
          rows={5}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.customGreeting}
        />
        <div className="ag-flex ag-items-center ag-justify-between">
          <div>
            {formik.errors.customGreeting && formik.touched.customGreeting && (
              <FormError>{formik.errors.customGreeting}</FormError>
            )}
          </div>
          <div className="ag-text-xs ag-text-neutral-400 ag-text-right ag-mt-1">
            {formik.values.customGreeting.length}/500 characters
          </div>
        </div>
      </div>
      {featureFlags.conversationTimer && (
        <div className="ag-flex ag-flex-col">
          <label className="ag-mb-4 ag-text-white" htmlFor="timerConfig">
            <div>Timer configuration(JSON)</div>
            <div className="ag-text-sm ag-text-neutral-400">
              <h3>JSON Structure Description</h3>

              <h4>1. `duration` (number, seconds)</h4>
              <p>This represents the total duration of the timer in seconds.</p>

              <h4>2. `events` (array of objects)</h4>
              <p>
                This is an array that holds a list of events to be executed at
                specific time points. Each event is an object with the following
                properties:
              </p>
              <ul>
                <li>
                  <strong>type</strong> (string) - The type of action to
                  perform. It can be one of the following values:
                  <ul>
                    <li>
                      <code>echo</code> - A message will be echoed at the
                      specified time point.
                    </li>
                    <li>
                      <code>system_prompt</code> - A system prompt for the LLM.
                    </li>
                    <li>
                      <code>end_call</code> - The call will end at the specified
                      time point.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>time_point</strong> (number, seconds) - The time in
                  seconds when the action should take place.
                </li>
                <li>
                  <strong>value</strong> (string, optional) - The value that is
                  used with <code>echo</code> and <code>system_prompt</code>
                  events. This property is not required for the{' '}
                  <code>end_call</code> action.
                </li>
              </ul>
            </div>
          </label>
          <textarea
            className={clsx(
              classNameBg,
              'ag-border ag-border-neutral-600 ag-py-2 ag-px-3 ag-rounded-md ag-text-sm ag-outline-none ag-ring-0 focus:ag-outline-none focus:ag-ring-0',
              formik.errors.timerConfig && formik.touched.timerConfig
                ? 'ag-text-red-900  focus:ag-border-red-500'
                : 'ag-text-white placeholder:ag-text-gray-400 focus:border-primary-800'
            )}
            name="timerConfig"
            id="timerConfig"
            rows={5}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.timerConfig}
          />
          <div className="ag-flex ag-items-center ag-justify-between">
            <div>
              {formik.errors.timerConfig && formik.touched.timerConfig && (
                <FormError>{formik.errors.timerConfig}</FormError>
              )}
            </div>
          </div>
        </div>
      )}

      <ChooseModelAiModal
        models={aiModels.map((item) => ({
          value: item.value,
          description: item.description,
          name: item.name,
          icon: item.iconBlack
        }))}
        onChoose={(value) => formik.setFieldValue('model', value)}
        setOpen={setOpenChoseAiModelModal}
        isOpen={openChoseAiModelModal}
      />
    </div>
  );
};
