import { AxiosResponse } from 'axios';

import { Pagination, tavusHttpService } from 'shared';
import { Replica } from '../model';

const replicasEndpoint = '/replica/';

export const replicaApi = {
  fetch: async (id: string) => {
    const { data }: AxiosResponse<Replica> = await tavusHttpService.get(
      `${replicasEndpoint}/${id}`
    );

    return data;
  },
  fetchAll: async (page: number, replicaName: string) => {
    const { data }: AxiosResponse<Pagination<Replica>> =
      await tavusHttpService.get(replicasEndpoint, {
        params: {
          page,
          replica_name: replicaName
        }
      });

    return data;
  }
};
